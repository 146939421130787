import React from 'react'
import { Text, View, ScrollView } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import RatingSelection from 'components/RatingSelection'
import { openURL } from 'lib/utils'
import s from 'styles'
import { LessonType, LessonAttachmentType, DisplayLessonInfoTypes } from './LessonTypes'
import { lessonsActions } from 'store/lessons'
import { useDispatch } from 'react-redux'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

interface ItemType {
  lesson?: LessonType,
  text?: string,
  index?: number,
  url?: string,
  numberOfLines?: number,
}

const DisplayAttachmentLink = ({ name, type, url }: LessonAttachmentType) => {
  if (type === 'file')
    return (
      <View style={[s.ml32]}>
        <View >
          <Text onPress={() => openURL(url, true)} numberOfLines={1} style={[s.ml8, s.mt16, s.f16, s.textBold, s.textGreen, s.textUnderline]}>{name}</Text>
        </View>
      </View>
    )
  else return null
}

const BulletPoint = ({ text, url, numberOfLines = 1 }: { text: string; url?: string, numberOfLines?: number }) => (
  <View style={[s.flexRow, s.alignStart]}>
    <Text style={[s.f16, s.mr8]}>{'\u2022'}</Text>
    {url
      ? (<Text
        onPress={() => openURL(url, true)}
        numberOfLines={numberOfLines}
        ellipsizeMode='tail'
        style={[s.f16, s.textGreen]}
      >
        {text}
      </Text>
      )
      : (<Text
        numberOfLines={numberOfLines}
        ellipsizeMode='tail'
        style={[s.f16]}
      >
        {text}
      </Text>
      )
    }
  </View>
)

const DisplayStepInstructions = ({ text, index }: ItemType) => {
  return (
    <View>
      <View
        style={[
          s.w20,
          s.h20,
          s.br50p,
          s.b1,
        ]}
      >
        <Text style={[s.textCenter, s.textBold, s.f14]}>
          {index + 1}
        </Text>
      </View>
      <View style={[{ marginLeft: 40 }, { marginTop: -20 }]}>
        <Text style={[s.f16]} >
          {text.replace(/•/g, '\n * ')}
        </Text>
      </View>
    </View>
  )
}

const DisplayInstructions = ({ lesson }: DisplayLessonInfoTypes) => {
  const { isDesktop } = useDetectDeviceType()
  return (
    <View style={[!isDesktop && s.pr16]}>
      {lesson.studentSteps.map((entry, index) => (
        <View key={index} style={[s.mb32]}>
          <DisplayStepInstructions key={index} index={index} lesson={lesson} text={entry.instructions} />
          {lesson.studentSteps[index].attachments.map((entry, index) => (
            <DisplayAttachmentLink key={index} name={entry.name} url={entry.url} type={entry.type} />))}
        </View>
      ))}
    </View >
  )
}

const DisplayBulletPoint = ({ text, url, numberOfLines }: ItemType) => {
  return (
    <View style={[s.mb8]}>
      <BulletPoint text={text} url={url} numberOfLines={numberOfLines} />
    </View>
  )
}

const DisplayMaterialsAndResources = ({ lesson }: DisplayLessonInfoTypes) => {
  const { isDesktop } = useDetectDeviceType()
  const sectionStyle = isDesktop
    ? [s.w50p]
    : [s.w100p, s.mb16]
  return (
    <View>
      <View style={isDesktop
        ? [s.flexRow, s.justifyBetween]
        : [s.flexColumn, s.alignStart]
      }>
        <View style={sectionStyle}>
          <Text style={[s.f18, s.textBold, s.textBlack]}>Materials</Text>
          <View style={[s.mt16, s.mr16]}>
            {lesson.materials.map((entry, index) => (
              <DisplayBulletPoint key={index} index={index} url={entry.item.toLowerCase().includes('activity sheet') && lesson.studentPdfUrl} lesson={lesson} text={entry.item} />
            ))}</View>
        </View>
        <View style={sectionStyle}>
          <Text style={[s.f18, s.textBold, s.textBlack]}>Resources</Text>
          <View style={[s.mt16, s.mr8, s.textGreen]}>
            {lesson.codingStyles[0] === 'OzoBlockly' && (<DisplayBulletPoint url={'https://ozoblockly.com/editor'} text={'Blockly Editor'} />)}
            {lesson.codingStyles[0] === 'Color Codes' && (<DisplayBulletPoint url={'https://files.ozobot.com/resources/ozobot-color-code-chart.pdf'} text={'Color Code Chart'} />)}
          </View>
        </View>
      </View>
    </View>
  )
}

export const DisplayChecklist = ({ lesson }: DisplayLessonInfoTypes) => {
  const { isDesktop } = useDetectDeviceType()
  return (
    <View style={[isDesktop ? s.ml32 : s.pr16]}>
      {lesson.checklist.map((entry, index) => (
        <DisplayBulletPoint key={index} index={index} lesson={lesson} text={entry} numberOfLines={999} />
      ))}
    </View>
  )
}

const DisplayFeedback = ({ lesson, lessonRating, isPublicView }: DisplayLessonInfoTypes) => {
  const dispatch = useDispatch()
  const onSubmit = (rating: number) => {
    dispatch(lessonsActions.rateLessonRequest({
      rating,
      lessonId: lesson.id,
      applyForUser: !isPublicView,
    }))
  }
  return (
    <RatingSelection
      text="How do you feel about this lesson?"
      submittedRating={lessonRating}
      lockOnSubmit={isPublicView}
      onSubmit={onSubmit}
    />
  )
}

interface DisplayCatagoryType {
  text: string,
  hide: boolean,
  selected: number,
  index: number,
  key: number,
  isMobile: boolean,
  setSelected: (arg0: number) => void,
}
const DisplayCatagory = ({ index, key, text, selected, setSelected, hide, isMobile }: DisplayCatagoryType) => {
  if (hide) return null
  return (
    <View style={[s.justifyEnd, s.flex1, s.alignStretch]} key={key}>
      <TouchableOpacity
        style={[
          s.flexRow,
          s.justifyEvenly,
          s.alignStretch,
          s.bBottom3,
          index === selected ? s.bGreen : s.bGrayLighter,
        ]}
        onPress={() => setSelected(index)}
      >
        <Text style={[
          index === selected ? s.textBlack : s.textGray,
          s.textBold,
          isMobile ? s.f14 : s.f18,
          s.textCenter,
          isMobile ? s.mb4 : s.mb8,
        ]} >
          {text}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

interface TabbedLessonInfoType {
  lesson: LessonType,
  lessonRating?: number | undefined,
  isPublicView?: boolean,
}

const Catagories = [
  { name: 'Materials & Resources', hideTest: () => false },
  { name: 'Instructions', hideTest: () => false },
  { name: 'Completion Checklist', hideTest: (lesson) => (!(lesson.checklist?.length > 0)) },
  { name: 'Feedback', hideTest: () => false },
]

export function TabbedLessonInfo({ lesson, lessonRating, isPublicView }: TabbedLessonInfoType) {
  const [selected, setSelected] = React.useState(0)
  const {isDesktop, isMobile } = useDetectDeviceType()
  return (
    <View style={[s.bgWhite, !isDesktop ? s.px16 : null]}>
      <View style={[s.mt32]}>
        <View style={[s.flexRow, s.justifyEvenly, s.mb32]}>
          {Catagories.map((entry, index) =>
            <DisplayCatagory
              index={index}
              key={index}
              hide={entry.hideTest(lesson)}
              text={entry.name}
              selected={selected}
              setSelected={setSelected}
              isMobile={isMobile}
            />,
          )}
        </View>
        <ScrollView contentContainerStyle={[{ height: 300 }]} >
          {selected === 0 && <DisplayMaterialsAndResources lesson={lesson} />}
          {selected === 1 && <DisplayInstructions lesson={lesson} />}
          {selected === 2 && <DisplayChecklist lesson={lesson} />}
          {selected === 3 && <DisplayFeedback lesson={lesson} lessonRating={lessonRating} isPublicView={isPublicView} />}
        </ScrollView>
      </View>
    </View>
  )
}
