import React from 'react'
import { View, Text } from 'react-native'
import s from 'styles'

export default function SubmissionSection({
  text,
  secondaryText,
  components,
  zIndex = '0',
  isAdminUser = false
}) {
  return (
    <View style={[s.mb20, s['zIndex' + zIndex]]}>
      {text && (
        <View style={[s.mb8]}>
          <Text style={[s.f18, s.textBlack, s.mb4]}>{text}</Text>
          <Text style={[s.f14, s.textItalic]}>{secondaryText}</Text>
        </View>
      )}
      {components &&
        components
          .filter(e => isAdminUser || !e.adminOnly)
          .map(({ component, ...props }, i) =>
            // CLASS-2092: Need to make key unique to refresh component tree
            React.createElement(component, {
              key: `${props.field}:${i}`,
              ...props,
            })
          )}
    </View>
  )
}
