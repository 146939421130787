import React from 'react'
import { View } from 'react-native'
import { some, sortBy, xor } from 'lodash'
import { CheckBoxItem } from 'components/Form'
import SubmissionLabel from './SubmissionLabel'
import withSubmissionForm from './withSubmissionForm'
import DropDownMultiSelect from 'components/DropDown/DropDownMultiSelect'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

const SubmissionCheckboxGroup = ({
  field,
  values,
  hasSeparator,
  form,
  ...labelProps
}) => {
  const orderedValues = values.map(({ value }) => value)
  const onChange = obj => {
    const diff = xor(form[field], obj[field])
    const sorted = sortBy(diff, item => orderedValues.indexOf(item))

    form.updateForm({ [field]: sorted })
  }

  const { isMobile } = useDetectDeviceType();

  return (
    <View
      style={[s.mb20, hasSeparator && [s.pb20, s.bBottom1, s.bGrayLighter]]}
    >
      <SubmissionLabel
        {...labelProps}
        hasError={some(form.errors, { field })}
      />

      {/* <DropDownMultiSelect
        title={'Choose'}
        width="100%"
        field="category"
        fn={(field, option) => null}
        options={values}
        errors={some(form.errors, { field })}
        value=''
      /> */}

      <View style={[s.flexRow, s.flexWrap]}>
        {values.map(({ label, value }, i) => (
          <View key={i} style={[s.mb8, isMobile ? s.col6 : s.col3]}>
            <CheckBoxItem
              isChecked={form[field].includes(value)}
              label={label}
              name={field}
              onChange={onChange}
              value={value}
            />
          </View>
        ))}
      </View>
    </View>
  )
}

export default withSubmissionForm(SubmissionCheckboxGroup)
