import React from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import qs from 'qs'
import { BOTS, CODING_STYLES, GRADES, SUBJECTS, CATEGORIES } from 'lib/utils.js'
import { modalShow } from 'store/modal'
import { lessonsActions, lessonSelector } from 'store/lessons'
import { ozobotAuthorSignedInSelector } from 'store/user'
import SubmissionAttachmentsGroup from './components/SubmissionAttachmentsGroup'
import SubmissionBackgroundGroup from './components/SubmissionBackgroundGroup'
import SubmissionCheckboxGroup from './components/SubmissionCheckboxGroup'
import SubmissionCheckboxSingle from './components/SubmissionCheckboxSingle'
import SubmissionCheckboxesGroup from './components/SubmissionCheckboxesGroup'
import SubmissionCoverImageUploader from './components/SubmissionCoverImageUploader'
import SubmissionMaterialsGroup from './components/SubmissionMaterialsGroup'
import SubmissionNumberTextarea from './components/SubmissionNumberTextarea'
import SubmissionObjectivesGroup from './components/SubmissionObjectivesGroup'
import SubmissionReviewAccordion from './components/SubmissionReviewAccordion'
import SubmissionRTE from './components/SubmissionRTE'
import SubmissionSection from './components/SubmissionSection'
import SubmissionStandardsGroup from './components/SubmissionStandardsGroup'
import SubmissionStep from './components/SubmissionStep'
import SubmissionStepsGroup from './components/SubmissionStepsGroup'
import SubmissionTextarea from './components/SubmissionTextarea'
import SubmissionLayout from './components/SubmissionLayout'
import withSteps from './components/withSteps'
import { withFolderAttachment } from 'store/lessons/util'
import { withDeviceHook } from 'hooks/withDeviceHook'

const steps = [
  {
    text: '1. Tell Us About Your Lesson',
    secondaryText: 'All fields are required unless marked as optional',
    slug: 'About Lesson',
    component: SubmissionStep,
    components: [
      {
        text: 'A. Lesson Overview',
        component: SubmissionSection,
        components: [
          {
            component: SubmissionTextarea,
            text: 'Title',
            field: 'title',
            maxLength: 80,
          },
          {
            component: SubmissionTextarea,
            text: 'Quick Summary',
            field: 'summary',
            maxLength: 250,
          },
          {
            component: SubmissionObjectivesGroup,
            text: 'Objectives & Outcomes',
            beforeInput: 'Students will ',
            secondaryLink: {
              text: 'Learn more about writing lesson objectives here',
              to:
                'https://ozobot.com/blog/how-to-write-clear-lesson-objectives',
            },
            field: 'objectives',
          },
        ],
      },
      {
        text: 'B. Lesson Details',
        component: SubmissionSection,
        components: [
          {
            component: SubmissionNumberTextarea,
            text: 'Lesson Duration',
            secondaryText: 'The time (minutes) to complete the whole lesson.',
            field: 'duration',
          },
          {
            component: SubmissionCheckboxGroup,
            text: 'Grade Level(s)',
            secondaryText: 'Select all that apply',
            field: 'grades',
            values: GRADES,
            hasSeparator: true,
          },
          {
            component: SubmissionCheckboxGroup,
            text: 'Subjects/Topics',
            secondaryText:
              'Choose the most relevant subject(s). Select up to 3.',
            field: 'subjects',
            values: SUBJECTS,
          },
          {
            component: SubmissionObjectivesGroup,
            text: 'Other Subjects',
            secondaryText: 'Optional. Eg. Astronomy',
            field: 'subjectSuggestions',
            hasSeparator: true,
          },
          {
            text: 'Coding Styles',
            component: SubmissionCheckboxGroup,
            field: 'codingStyles',
            values: CODING_STYLES,
            hasSeparator: true,
          },
          {
            text: 'Tested With',
            component: SubmissionCheckboxGroup,
            field: 'bots',
            values: BOTS,
            hasSeparator: true,
          },
          {
            text: 'Main Listing Categories',
            component: SubmissionCheckboxGroup,
            field: 'categories',
            values: CATEGORIES,
            hasSeparator: true,
            adminOnly: true,
          },
          {
            component: SubmissionCheckboxesGroup,
            text: 'Other',
            secondaryText: 'Optional',
            components: [
              {
                component: SubmissionCheckboxSingle,
                field: 'prereader',
                text: 'Pre-Reader/ESL friendly',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    text: '2. Preparation',
    secondaryText:
      'This helps the teacher prepare for the lesson before the class session',
    slug: 'Preparation',
    component: SubmissionStep,
    components: [
      {
        text: 'A. Student Materials',
        component: SubmissionSection,
        components: [
          {
            component: SubmissionMaterialsGroup,
            field: 'materials',
            initialItem: { count: '1', item: '', grouping: 'Per Group' },
          },
        ],
      },
      // {
      //   text: 'B. Teacher Materials (Optional)',
      //   component: SubmissionSection,
      //   components: [
      //     {
      //       component: SubmissionMaterialsGroup,
      //       field: 'materials',
      //     },
      //   ],
      // },
      {
        text: 'C. Background Knowledge (Optional)',
        component: SubmissionSection,
        components: [
          {
            component: SubmissionBackgroundGroup,
            field: 'prerequisites',
            initialItem: { ability: 'Completed Lesson', description: '' },
          },
        ],
      },
      {
        text: 'D. Lesson Tips (Optional)',
        secondaryText: `Add tips for the educator that don't fit into Direct Instruction or Student Practice. You can always return to this page to add more.`,
        component: SubmissionSection,
        components: [
          {
            component: SubmissionRTE,
            field: 'teacherTips',
          },
        ],
      },
    ],
  },
  {
    text: '3. Direct Instruction (Teacher-Facing Instructions)',
    slug: 'Direct Instruction',
    secondaryText: `These are the steps the educator will read. Include any front loading, modeling or explicit instruction before students work independently or in groups.`,
    component: SubmissionStep,
    components: [
      {
        component: SubmissionSection,
        components: [
          {
            component: SubmissionStepsGroup,
            text: 'Instruction',
            field: 'teacherSteps',
            hasAttachments: true,
            initialItem: { instructions: '', goal: '', attachments: [] },
          },
        ],
      },
    ],
  },
  {
    text: '4. Student Practice (Student-Facing Instructions)',
    secondaryText: `These are step-by-step instructions delivered directly to the students as they work independently or in groups`,
    slug: 'Student Practice',
    component: SubmissionStep,
    components: [
      {
        text: 'Student Instructions',
        component: SubmissionSection,
        components: [
          {
            component: SubmissionStepsGroup,
            text: 'Instruction',
            field: 'studentSteps',
            hasGoal: true,
            hasAttachments: true,
            attachmentsText: 'Please upload any student resources, videos, etc. (Max. size: 512 MB videos, 10 MB all other files)',
            hasTime: true,
            goalSecondaryText:
              'What should the student or Ozobot complete during this step?',
            goalHeading: 'Goal',
            initialItem: { instructions: '', goal: '', attachments: [] },
          },
        ],
      },
      {
        text: 'Lesson Extension (Optional)',
        secondaryText: `Add student instructions for a lesson extension.`,
        component: SubmissionSection,
        components: [
          {
            component: SubmissionStepsGroup,
            text: 'Instruction',
            field: 'extensions',
            hasGoal: true,
            hasAttachments: true,
            attachmentsText: 'Please upload any student resources, videos, etc. (Max. size: 512 MB videos, 10 MB all other files)',
            hasTime: true,
            goalSecondaryText:
              'What should the student or Ozobot complete during this step?',
            goalHeading: 'Goal',
            initialItem: { instructions: '', goal: '', attachments: [] },
          },
        ],
      },
    ],
  },
  {
    text: '5. Supplements ',
    slug: 'Supplements',
    component: SubmissionStep,
    components: [
      {
        text: 'A. Lesson Closure (Optional)',
        secondaryText: `Give tips for how to wrap up the lesson and assess student learning. (Want to add an attachment? Use Part C, below.)`,
        component: SubmissionSection,
        components: [
          {
            component: SubmissionTextarea,
            field: 'lessonClosure',
          },
        ],
      },
      {
        text: 'B. Academic Standards (At least one standard required)',
        secondaryText: "Choose a category from the dropdown on the left. In the blank on the right, begin typing the number of the standard. An auto-filled list should appear. Click on the standard to add to the lesson. If using the ‘Other’ category, type the entire standard in the blank.",
        component: SubmissionSection,
        zIndex: '1',
        components: [
          {
            component: SubmissionStandardsGroup,
            field: 'academicStandards',
          },
        ],
      },
      {
        text: 'C. Add Other Attachments (Optional)',
        secondaryText:
          'Please upload any student handouts, videos, sample solutions, etc. (Max. size: 1 GB videos, 10 MB all other files)',
        component: SubmissionSection,
        components: [
          {
            component: SubmissionAttachmentsGroup,
            field: 'attachments',
          },
        ],
      },
      {
        text: 'Add a Cover Image',
        component: SubmissionSection,
        components: [
          {
            component: SubmissionCoverImageUploader,
            field: 'coverImageUrl',
            errorMessage: 'Use an image file, like JPEG or PNG',
          },
        ],
      },
    ],
  },
  {
    text: 'Review Your Lesson',
    secondaryText: `Make sure everything looks correct!`,
    slug: 'Review',
    component: SubmissionStep,
    components: [
      {
        text: '1. About Lesson',
        editUrl: '/lessons/creator/1',
        component: SubmissionReviewAccordion,
        values: [
          { label: 'Title', name: 'title', type: 'text' },
          { label: 'Summary', name: 'summary', type: 'text' },
          {
            label: 'Objectives',
            name: 'objectives',
            type: 'bullets',
            beforeItem: 'Students will ',
          },
          { label: 'Duration', name: 'duration', type: 'text' },
          { label: 'Grade Level(s)', name: 'grades', type: 'array' },
          { label: 'Subjects/Topics', name: 'subjects', type: 'array' },
          {
            label: 'Subjects Suggestion',
            name: 'subjectSuggestions',
            type: 'array',
          },
          { label: 'Coding Styles', name: 'codingStyles', type: 'array' },
          { label: 'Prereader', name: 'prereader', type: 'text' },
          { label: 'Bots', name: 'bots', type: 'array' },
        ],
      },
      {
        text: '2. Preparation',
        editUrl: '/lessons/creator/2',
        component: SubmissionReviewAccordion,
        values: [
          {
            label: 'Materials',
            name: 'materials',
            type: 'list',
            order: ['count', 'item', 'grouping'],
          },
          {
            label: 'Background Knowledge',
            name: 'prerequisites',
            type: 'list',
            order: ['ability', 'description'],
          },
          { label: 'Teacher Tips', name: 'teacherTips', type: 'markdown' },
        ],
      },
      {
        text: '3. Direct Instruction',
        editUrl: '/lessons/creator/3',
        component: SubmissionReviewAccordion,
        values: [
          { label: 'Teacher Steps', name: 'teacherSteps', type: 'steps' },
        ],
      },
      {
        text: '4. Student Practice',
        editUrl: '/lessons/creator/4',
        component: SubmissionReviewAccordion,
        values: [
          { label: 'Student Steps', name: 'studentSteps', type: 'steps' },
          { label: 'Extensions', name: 'extensions', type: 'steps' },
        ],
      },
      {
        text: '5. Supplements',
        editUrl: '/lessons/creator/5',
        component: SubmissionReviewAccordion,
        values: [
          { label: 'Lesson Closure', name: 'lessonClosure', type: 'text' },
          {
            label: 'Academic Standards',
            name: 'academicStandards',
            type: 'bullets',
          },
          { label: 'Attachments', name: 'attachments', type: 'attachments' },
          { label: 'Cover Image', name: 'coverImageUrl', type: 'image' },
        ],
      },
    ],
  },
]

const formInitialState = {
  academicStandards: [],
  otherAcademicStandards: [],
  attachments: [],
  prerequisites: [
    {
      ability: 'Completed Lesson',
      description: '',
    },
  ],
  bots: ['Evo'],
  codingStyles: [],
  coverImageUrl: '',
  duration: 0,
  extensions: [
    {
      instructions: '',
      goal: '',
      attachments: [],
    },
  ],
  grades: [],
  lessonClosure: '',
  materials: [
    {
      count: '1',
      item: 'Evo',
      grouping: 'Per Group',
    },
  ],
  objectives: [],
  prereader: false,
  hasVideo: false,
  studentSteps: [
    {
      instructions: '',
      goal: '',
      attachments: [],
    },
  ],
  subjects: [],
  subjectSuggestions: [],
  title: '',
  summary: '',
  teacherSteps: [
    {
      instructions: '',
      attachments: [],
    },
  ],
  teacherTips: '',
  categories: [],
}
/**
 * URL: /lessons/creator/:page
 * qs params: id
 */
class Creator extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      form: isEmpty(props.lesson)
        ? withFolderAttachment(formInitialState)
        : props.lesson,
      errors: [],
    }
  }

  componentDidUpdate(prevProps) {
    // Update form state after lessonsViewRequest succeeds
    if (prevProps.lesson.id !== this.props.lesson.id) {
      this.updateForm(this.props.lesson)
    }
  }

  isValid() {
    // predicates
    const undefinedP = field => field === undefined || field === null
    const truthyP = field => !field
    const gt0P = field => !(field > 0)
    const arrayP = field => field.length === 0
    const stepsP = field =>
      field.length === 0 || field.some(step => !step.instructions)

    const hasLettuce = !!(this.state?.form?.codingStyles?.find(s => s.toLowerCase().includes('lettuce')))

    const errors = [
      { label: 'Title', field: 'title', p: truthyP },
      { label: 'Summary', field: 'summary', p: truthyP },
      { label: 'Objectives', field: 'objectives', p: arrayP },
      { label: 'Duration', field: 'duration', p: gt0P },
      { label: 'Grades', field: 'grades', p: arrayP },
      { label: 'Subjects', field: 'subjects', p: arrayP },
      { label: 'Prereader', field: 'prereader', p: undefinedP },
      { label: 'Coding Styles', field: 'codingStyles', p: arrayP },
      { label: 'Tested With', field: 'bots', p: (f) => arrayP(f) && !hasLettuce },

      { label: 'Direct Instruction', field: 'teacherSteps', p: stepsP },
      { label: 'Student Practice', field: 'studentSteps', p: stepsP },

      { label: 'Academic Standards', field: 'academicStandards', p: arrayP },
      { label: 'Cover Image', field: 'coverImageUrl', p: truthyP },
    ]
      .filter(e => e.p(this.state.form[e.field]))
      .map(e => ({ ...e, p: undefined }))

    this.setState({
      errors,
      errorMessage: errors.map(({ label }) => label).join(', '),
    })

    return !errors.length
  }

  onSave = () => {
    this.props.save(this.state.form)
  }

  onSubmit = () => {
    if (this.isValid()) {
      this.props.submit(this.state.form)
    }
  }

  updateForm = obj => {
    const updatedFields = Object.keys(obj)
    this.setState(prevState => {
      const newErrors = prevState.errors.filter(
        error => !updatedFields.includes(error.field)
      )

      return {
        form: Object.assign({}, prevState.form, obj),
        errors: newErrors,
        errorMessage: newErrors.map(({ label }) => label).join(', '),
      }
    })
  }

  render() {

    // TODO: Move to componentShouldUpdate? Data is still fetching
    if (this.props.id && !this.state.form.id) {
      return null
    }

    return (<SubmissionLayout
      step={this.props.step}
      steps={steps}
      isAdminUser={this.props.isAdminUser}
      text={'Lesson Creator'}
      formProviderValue={{
        ...this.state.form,
        errors: this.state.errors,
        updateForm: this.updateForm,
      }}
      scrollView={this.props.scrollView}
      isFetching={this.props.isFetching}
      errorMessage={this.state.errorMessage}
      goTo={this.props.goTo}
      onSave={this.onSave}
      onSubmit={this.onSubmit}
    />)
  }
}

Creator.steps = steps

const mapStateToProps = (state, ownProps) => {
  const { id } = qs.parse(ownProps.location.search, {
    ignoreQueryPrefix: true,
  })

  return {
    isFetching: state.lessons.isFetching,
    lastUpdatedId: state.lessons.lastUpdatedId,
    lesson: lessonSelector(state, id),
    isAdminUser: ozobotAuthorSignedInSelector(state)
  }
}

export default withDeviceHook(connect(mapStateToProps, {
  modalShow,
  lessonsViewRequest: lessonsActions.viewRequest,
  lessonsUpdateRequest: lessonsActions.updateRequest,
  lessonsCreateRequest: lessonsActions.createRequest,
})(withSteps(Creator, { uri: 'creator', type: 'step' })))
